<template>
  <div class="campaign-edit-page">
    <h2>Edit Ebook</h2>

    <ebook-form
      v-if="ebook"
      v-model="ebook"
    />

    <b-button @click="submit">Save</b-button>
  </div>
</template>

<script>
import EbookForm from '../components/EbookForm.vue'

export default {
  name: 'EbookEdit',

  components: {
    EbookForm
  },

  computed: {
    ebookId () {
      return this.$route.params.id
    }
  },

  data () {
    return {
      ebook: null
    }
  },

  mounted () {
    this.fetch()
  },

  methods: {
    async fetch () {
      const resp = await this.$ebooks.get(this.ebookId)
      this.ebook = resp.data.data
    },

    validate () {
      let valid = true

      return valid
    },

    async submit () {
      if (!this.validate()) {
        console.warn('Invalid form')
        return
      }

      try {
        await this.$ebooks.update(this.ebookId, this.ebook)
      }
      catch (e) {
        console.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
